import React, { useState } from 'react';
import './Header.scss';
import { links } from '../../Utils/Links';
import { NavLink } from 'react-router-dom';
import { socialLinks } from '../../Utils/SocialLinks';
import { useAuth } from '../../Utils/Auth/AuthContext';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, logout } = useAuth();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSignOut = async () => {
    await logout();
  };

  return (
    <header className="header">
      <nav className="header-nav">
        {isAuthenticated && (
          <button onClick={handleSignOut} className="signout-button">
            Sign Out
          </button>
        )}
        <div className="titleImage"></div>
        <div className="hamburger" onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
          {links.map((link, index) => (
            <li key={index}>
              <NavLink
                to={link.href}
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                {link.text}
              </NavLink>
            </li>
          ))}
          {isAuthenticated && (
            <li>
              <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'active' : '')}>
                Dashboard
              </NavLink>
            </li>
          )}
        </ul>
      </nav>

      {isOpen && (
        <div className="mobile-nav">
          <div className="mobile-nav-content">
            <div className="mobile-logo">
              <div className="titleImage"></div>
            </div>
            <ul className="mobile-nav-links">
              {links.map((link, index) => (
                <li key={index}>
                  <NavLink
                    to={link.href}
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    onClick={toggleMenu}
                  >
                    {link.text}
                  </NavLink>
                </li>
              ))}
              {isAuthenticated && (
                <li>
                  <NavLink
                    to="/dashboard"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    onClick={toggleMenu}
                  >
                    Dashboard
                  </NavLink>
                </li>
              )}
              {isAuthenticated && (
                <li>
                  <button onClick={handleSignOut} className="signout-button">
                    Sign Out
                  </button>
                </li>
              )}
            </ul>
            <div className="mobileHamburger" onClick={toggleMenu}>
              {isOpen ? <FaTimes /> : <FaBars />}
            </div>
            <div className="mobile-social-links">
              {socialLinks.map((social, index) => (
                <a key={index} href={social.href} target="_blank" rel="noopener noreferrer">
                  {social.icon}
                </a>
              ))}
            </div>
            <div className="mobile-footer">
              &copy; Rare Candyz 2024. All rights reserved. <br />
              <p className="credit-footer">
                Designed by{' '}
                <a href="https://www.davinchiupka.com" target="_blank" rel="noopener noreferrer">
                  Davin Chiupka
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
