import React from "react";
import "./SkeletonLoader.scss";

const SkeletonLoader = ({ width, height }) => {
  return (
    <div
      className="skeleton-loader"
      style={{
        width: width || "100%",
        height: height || "100%",
      }}
    ></div>
  );
};

export default SkeletonLoader;
