import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const useFetchVideos = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const videoCollection = collection(db, "galleryYoutubeVideos");
        const videoSnapshot = await getDocs(videoCollection);
        const videoList = videoSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setVideos(videoList.map((video) => video.videoUrl));
      } catch (error) {
        console.error("Error fetching videos from Firestore: ", error);
      }
    };

    fetchVideos();
  }, []);

  return videos;
};

export default useFetchVideos;
