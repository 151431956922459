import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc
} from "firebase/firestore";
import {
  ref,
  deleteObject,
} from "firebase/storage";
import { storage, db } from "../../firebase";
import ImageUpload from "../../Components/imageUpload/imageUpload";
//import VideoUpload from "../../Components/videoUpload/videoUpload";
import YouTubeLinkUpload from "../../Components/YoutubeLinkUpload/YoutubeLinkUpload";
import EditYouTubeVideoModal from "../../Components/UI-Elements/EditYoutubeModal/EditYoutubeModal";
import EditImageModal from "../../Components/UI-Elements/EditImageModal/EditImageModal";
import Button from "../../Components/UI-Elements/Button/Button";
import "./DashboardPage.scss";

const DashboardPage = () => {
  const [activeTab, setActiveTab] = useState("images");

  const [authors, setAuthors] = useState([]);
  const [artists, setArtists] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const [author, setAuthor] = useState("");
  const [authorLink, setAuthorLink] = useState("");
  const [artist, setArtist] = useState("");
  const [artistLink, setArtistLink] = useState("");

  useEffect(() => {
    const fetchImages = async () => {
      const imageCollection = collection(db, "galleryImages");
      const imageSnapshot = await getDocs(imageCollection);
      const imageList = imageSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setImages(imageList);
    };

    const fetchVideos = async () => {
      const videoCollection = collection(db, "galleryYoutubeVideos");
      const videoSnapshot = await getDocs(videoCollection);
      const videoList = videoSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVideos(videoList);
    };

    const fetchAuthorsAndArtists = async () => {
      const authorCollection = collection(db, "authors");
      const artistCollection = collection(db, "artists");

      const [authorSnapshot, artistSnapshot] = await Promise.all([
        getDocs(authorCollection),
        getDocs(artistCollection),
      ]);

      const fetchedAuthors = authorSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const fetchedArtists = artistSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAuthors(fetchedAuthors);
      setArtists(fetchedArtists);
    };

    fetchImages();
    fetchVideos();
    fetchAuthorsAndArtists();
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setAuthor(image.author);
    setAuthorLink(image.authorLink);
    setArtist(image.artist);
    setArtistLink(image.artistLink);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsVideoModalOpen(false);
    setSelectedImage(null);
    setSelectedVideo(null);
    setAuthor("");
    setAuthorLink("");
    setArtist("");
    setArtistLink("");
  };

  const handleDeleteImage = async () => {
    if (
      selectedImage &&
      window.confirm("Are you sure you want to delete this image?")
    ) {
      try {
        const imageRef = ref(storage, selectedImage.imageUrl);
        await deleteObject(imageRef);

        const docRef = doc(db, "galleryImages", selectedImage.id);
        await deleteDoc(docRef);

        setImages(images.filter((image) => image.id !== selectedImage.id));
        closeModal();
      } catch (error) {
        console.error("Error deleting image:", error);
      }
    }
  };

  /*

  const handleDeleteVideo = async (videoId, s3FileName) => {
    if (videoId && window.confirm("Are you sure you want to delete this video?")) {
      try {
        const s3 = new AWS.S3({
          accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
          region: process.env.REACT_APP_AWS_REGION,
        });
  
        const params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: s3FileName,
        };
  
        await s3.deleteObject(params).promise();

        const docRef = doc(db, "galleryVideos", videoId);
        await deleteDoc(docRef);
  
        setVideos((prevVideos) => prevVideos.filter((video) => video.id !== videoId));
      } catch (error) {
        console.error("Error deleting video:", error);
      }
    }
  };

  */

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setIsVideoModalOpen(true);
  };

  const handleDeleteYoutubeVideo = async () => {
    if (selectedVideo && window.confirm("Are you sure you want to delete this video?")) {
      try {
        const docRef = doc(db, "galleryYoutubeVideos", selectedVideo.id);
        await deleteDoc(docRef);
  
        setVideos((prevVideos) => prevVideos.filter((video) => video.id !== selectedVideo.id));
        closeModal();
      } catch (error) {
        console.error("Error deleting video:", error);
      }
    }
  };

  const handleSaveVideoChanges = async (updatedVideo) => {
    if (selectedVideo) {
      try {
        const docRef = doc(db, "galleryYoutubeVideos", selectedVideo.id);
        await updateDoc(docRef, updatedVideo);

        setVideos(
          videos.map((video) =>
            video.id === selectedVideo.id
              ? { ...video, ...updatedVideo }
              : video
          )
        );
        closeModal();
      } catch (error) {
        console.error("Error updating video:", error);
      }
    }
  };
  

  const handleSaveChanges = async (updatedImage) => {
    if (selectedImage) {
      try {
        const docRef = doc(db, "galleryImages", selectedImage.id);
        await updateDoc(docRef, updatedImage);

        setImages(
          images.map((image) =>
            image.id === selectedImage.id
              ? { ...image, ...updatedImage }
              : image
          )
        );
        closeModal();
      } catch (error) {
        console.error("Error updating image:", error);
      }
    }
  };

  const handleAuthorChange = (e) => {
    const selectedAuthor = authors.find(
      (author) => author.name === e.target.value
    );
    if (selectedAuthor) {
      setAuthor(selectedAuthor.name);
      setAuthorLink(selectedAuthor.link);
    } else {
      setAuthor(e.target.value);
      setAuthorLink("");
    }
  };

  const handleArtistChange = (e) => {
    const selectedArtist = artists.find(
      (artist) => artist.name === e.target.value
    );
    if (selectedArtist) {
      setArtist(selectedArtist.name);
      setArtistLink(selectedArtist.link);
    } else {
      setArtist(e.target.value);
      setArtistLink("");
    }
  };

  return (
    <div className="dashboard-page">
      <header className="dashboard-header">
        <h1>Admin Dashboard</h1>
        <p>Manage gallery images and videos.</p>
      </header>
      <div className="tabs">
        <Button 
          label="Images" 
          onClick={() => setActiveTab("images")} 
          variant={activeTab === "images" ? "primary" : "secondary"} 
        />
        <Button 
          label="YouTube Videos" 
          onClick={() => setActiveTab("youtubeVideos")} 
          variant={activeTab === "youtubeVideos" ? "primary" : "secondary"} 
        />
        {/* Uncomment the following if you want to include video uploads as well */}
        {/* <Button 
          label="Videos" 
          onClick={() => setActiveTab("videos")} 
          variant={activeTab === "videos" ? "primary" : "secondary"} 
        /> */}
      </div>

      {/* Content based on selected tab */}
      {activeTab === "images" && (
        <section className="dashboard-section">
          <ImageUpload
            authors={authors}
            artists={artists}
            setAuthors={setAuthors}
            setArtists={setArtists}
            setImages={setImages}
            images={images}
          />
          <div className="image-gallery">
            <h3>Gallery Images</h3>
            <ul className="image-gallery-items">
              {images.map((image, index) => (
                <li key={image.id} onClick={() => handleImageClick(image)}>
                  <img src={image.imageUrl} alt={`Gallery ${index + 1}`} />
                  <p className="authorName">Author: {image.author}</p>
                  <a
                    className="authorLink"
                    href={image.authorLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Author Link
                  </a>
                  <p className="artistName">Artist: {image.artist}</p>
                  <a
                    className="artistLink"
                    href={image.artistLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Artist Link
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </section>
      )}

      {activeTab === "youtubeVideos" && (
        <section className="dashboard-section">
          <YouTubeLinkUpload setVideos={setVideos} />
          <div className="video-gallery">
            <h3>YouTube Videos</h3>
            <ul className="video-gallery-items">
              {videos.map((video, index) => (
                <li className="youtubeGalleryItem" key={video.videoName} onClick={() => handleVideoClick(video)}>
                  <iframe
                    width="1280"
                    height="720"
                    src={video.videoUrl}
                    title="Featured Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </li>
              ))}
            </ul>
          </div>
        </section>
      )}

      {isModalOpen && (
        <EditImageModal
          selectedImage={selectedImage}
          authors={authors}
          artists={artists}
          author={author}
          authorLink={authorLink}
          artist={artist}
          artistLink={artistLink}
          setAuthor={setAuthor}
          setAuthorLink={setAuthorLink}
          setArtist={setArtist}
          setArtistLink={setArtistLink}
          handleAuthorChange={handleAuthorChange}
          handleArtistChange={handleArtistChange}
          handleSaveChanges={handleSaveChanges}
          handleDeleteImage={handleDeleteImage}
          closeModal={closeModal}
        />
      )}

        {isVideoModalOpen && (
          <EditYouTubeVideoModal
            selectedVideo={selectedVideo}
            handleSaveChanges={handleSaveVideoChanges}
            handleDeleteVideo={handleDeleteYoutubeVideo}
            closeModal={closeModal}
          />
      )}
    </div>
  );
};

export default DashboardPage;
