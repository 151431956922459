import React, { useEffect, useState } from 'react';
import './EventViewBox.scss';
import Button from '../UI-Elements/Button/Button';

const EventViewBox = ({ event, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (event) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [event]);

  if (!event) return null;

  return (
    <div className={`event-viewbox-overlay ${isOpen ? 'open' : ''}`}>
      <div className={`event-viewbox-content ${isOpen ? 'open' : ''}`}>
        <button className="close-button" onClick={() => { setIsOpen(false); setTimeout(onClose, 300); }}>
          &times;
        </button>
        <h3>Next Event</h3>
        <img src={event.displayImage} alt={event.title} className="event-viewbox-image" />
        <div className="event-details">
          <h2>{event.title}</h2>
          <p><strong>Date:</strong> {event.formattedDate}</p>
          <a href={event.ticketLink} target="_blank" rel="noopener noreferrer">
            <Button variant="primary" label="Buy Tickets" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default EventViewBox;
