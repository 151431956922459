import { useState, useEffect } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";
import { storage, db } from "../../firebase";

const useFetchGalleryImages = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const storageRef = ref(storage, "images/");
        const result = await listAll(storageRef);
        const urls = await Promise.all(
          result.items.map((item) => getDownloadURL(item))
        );

        const shuffledUrls = urls.sort(() => 0.5 - Math.random());

        const imageCollection = collection(db, "galleryImages");
        const imageSnapshot = await getDocs(imageCollection);
        const imageMetaData = imageSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const imagesWithMetadata = shuffledUrls.slice(0, 4).map((url) => {
          const metadata = imageMetaData.find((meta) => meta.imageUrl === url);
          return {
            url,
            author: metadata ? metadata.author : "",
            authorLink: metadata ? metadata.authorLink : "",
            artist: metadata ? metadata.artist : "",
            artistLink: metadata ? metadata.artistLink : "",
          };
        });

        setGalleryImages(imagesWithMetadata);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching gallery images:", error);
        setLoading(false);
      }
    };

    fetchGalleryImages();
  }, []);

  return { galleryImages, loading };
};

export default useFetchGalleryImages;
