import React, { useState } from "react";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import { storage, db } from "../../firebase";
import Select from "../UI-Elements/Select/Select";
import ProgressBar from "../UI-Elements/ProgressBar/ProgressBar";
import "./imageUpload.scss";

const ImageUpload = ({
  authors,
  artists,
  setAuthors,
  setArtists,
  setImages,
  images,
}) => {
  const [imageFile, setImageFile] = useState(null);
  const [author, setAuthor] = useState("");
  const [authorLink, setAuthorLink] = useState("");
  const [artist, setArtist] = useState("");
  const [artistLink, setArtistLink] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setError] = useState("");

  // Set file size limit to 5MB (in bytes)
  const fileSizeLimit = 1 * 1024 * 1024;

  const handleImageUpload = async () => {
    if (!imageFile) {
      setError("Please select an image.");
      return;
    }

    if (imageFile.size > fileSizeLimit) {
      setError("File size exceeds the 1MB limit.");
      return;
    }

    if (!author) {
      setError("Please enter the author's name.");
      return;
    }

    if (!artist) {
      setError("Please enter the artist's name.");
      return;
    }

    setError("");

    const storageRef = ref(storage, `images/${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("Upload failed", error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        const docRef = await addDoc(collection(db, "galleryImages"), {
          imageUrl: downloadURL,
          author: author,
          authorLink: authorLink,
          artist: artist,
          artistLink: artistLink,
        });

        setImages([
          ...images,
          {
            id: docRef.id,
            imageUrl: downloadURL,
            author,
            authorLink,
            artist,
            artistLink,
          },
        ]);
        setImageFile(null);
        setAuthor("");
        setAuthorLink("");
        setArtist("");
        setArtistLink("");
        setUploadProgress(0);

        if (author && !authors.some((a) => a.name === author)) {
          await addDoc(collection(db, "authors"), {
            name: author,
            link: authorLink,
          });
          setAuthors([...authors, { name: author, link: authorLink }]);
        }

        if (artist && !artists.some((a) => a.name === artist)) {
          await addDoc(collection(db, "artists"), {
            name: artist,
            link: artistLink,
          });
          setArtists([...artists, { name: artist, link: artistLink }]);
        }
      }
    );
  };

  const handleAuthorChange = (e) => {
    const selectedAuthor = authors.find(
      (author) => author.name === e.target.value
    );
    if (selectedAuthor) {
      setAuthor(selectedAuthor.name);
      setAuthorLink(selectedAuthor.link);
    } else {
      setAuthor(e.target.value);
      setAuthorLink("");
    }
  };

  const handleArtistChange = (e) => {
    const selectedArtist = artists.find(
      (artist) => artist.name === e.target.value
    );
    if (selectedArtist) {
      setArtist(selectedArtist.name);
      setArtistLink(selectedArtist.link);
    } else {
      setArtist(e.target.value);
      setArtistLink("");
    }
  };

  return (
    <div className="image-upload">
      <h2>Add New Image</h2>
      <div className="form-group">
        <input
          type="file"
          onChange={(e) => setImageFile(e.target.files[0])}
        />
      </div>
      <div className="form-group">
        <Select
          label="Select Existing Author"
          options={authors.map((author) => ({
            value: author.name,
            label: author.name,
          }))}
          value={author}
          onChange={handleAuthorChange}
        />
        <input
          type="text"
          placeholder="New Author Name"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          placeholder="Author Link (e.g., Instagram URL)"
          value={authorLink}
          onChange={(e) => setAuthorLink(e.target.value)}
        />
      </div>
      <div className="form-group">
        <Select
          label="Select Existing Artist"
          options={artists.map((artist) => ({
            value: artist.name,
            label: artist.name,
          }))}
          value={artist}
          onChange={handleArtistChange}
        />
        <input
          type="text"
          placeholder="New Artist Name"
          value={artist}
          onChange={(e) => setArtist(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          placeholder="Artist Link (e.g., Website URL)"
          value={artistLink}
          onChange={(e) => setArtistLink(e.target.value)}
        />
      </div>
      <button onClick={handleImageUpload}>Upload Image</button>
      {uploadProgress > 0 && <ProgressBar progress={uploadProgress} />}
      <p className="errorMessage">{errorMessage}</p>
    </div>
  );
};

export default ImageUpload;
