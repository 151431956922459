import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Viewbox.scss';
import { FaTimes } from 'react-icons/fa';

const ViewBox = ({ imageSrc, author, authorLink, artist, artistLink, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (imageSrc) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [imageSrc]);

  return (
    <div className={`viewbox-backdrop ${isOpen ? 'open' : ''}`} onClick={() => { setIsOpen(false); setTimeout(onClose, 300); }}>
      <div className={`viewbox-content ${isOpen ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
        <div className="viewbox-header">
          <button className="viewbox-close" onClick={() => { setIsOpen(false); setTimeout(onClose, 300); }}>
            <FaTimes />
          </button>
        </div>
        <div className="viewbox-image">
          <img src={imageSrc} alt="Full view" />
        </div>
        <div className="viewbox-footer">
          {author && (
            <p>
              Image by:{' '}
              {authorLink ? (
                <a href={authorLink} target="_blank" rel="noopener noreferrer">
                  {author}
                </a>
              ) : (
                <span>{author}</span>
              )}
            </p>
          )}
          {artist && (
            <p>
              Artist:{' '}
              {artistLink ? (
                <a href={artistLink} target="_blank" rel="noopener noreferrer">
                  {artist}
                </a>
              ) : (
                <span>{artist}</span>
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

ViewBox.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  author: PropTypes.string,
  authorLink: PropTypes.string,
  artist: PropTypes.string,
  artistLink: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default ViewBox;
