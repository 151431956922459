import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Button from "../../Components/UI-Elements/Button/Button";
import "./YoutubeLinkUpload.scss";

const YouTubeLinkUpload = ({ setVideos }) => {
  const [videoName, setVideoName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleVideoNameChange = (e) => {
    setVideoName(e.target.value);
  };

  const handleVideoUrlChange = (e) => {
    setVideoUrl(e.target.value);
  };

  const handleUpload = async () => {
    if (!videoName || !videoUrl) {
      alert("Please provide both a video name and URL.");
      return;
    }

    setIsLoading(true);

    try {
      const videoCollection = collection(db, "galleryYoutubeVideos");
      const docRef = await addDoc(videoCollection, {
        videoName,
        videoUrl,
      });

      setVideos((prevVideos) => [
        ...prevVideos,
        { id: docRef.id, videoName, videoUrl },
      ]);

      setVideoName("");
      setVideoUrl("");
      alert("Video uploaded successfully!");
    } catch (error) {
      console.error("Error uploading video:", error);
      alert("Failed to upload video.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="youtube-link-upload">
      <h3>Upload YouTube Video</h3>
      <div className="input-group">
        <label htmlFor="videoName">Video Name:</label>
        <input
          type="text"
          id="videoName"
          value={videoName}
          onChange={handleVideoNameChange}
        />
      </div>
      <div className="input-group">
        <label htmlFor="videoUrl">YouTube URL:</label>
        <input
          type="text"
          id="videoUrl"
          value={videoUrl}
          onChange={handleVideoUrlChange}
          placeholder="https://www.youtube.com/embed/..."
        />
      </div>
      <Button
        label={isLoading ? "Uploading..." : "Upload Video"}
        onClick={handleUpload}
        variant="primary"
        disabled={isLoading}
      />
    </div>
  );
};

export default YouTubeLinkUpload;
