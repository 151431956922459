import React from "react";
import Select from "../Select/Select";
import Button from "../Button/Button"; 
import "./EditImageModal.scss"

const EditImageModal = ({
  authors,
  artists,
  author,
  authorLink,
  artist,
  artistLink,
  setAuthor,
  setAuthorLink,
  setArtist,
  setArtistLink,
  handleAuthorChange,
  handleArtistChange,
  handleSaveChanges,
  handleDeleteImage,
  closeModal,
}) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Edit Image Details</h2>

        <div className="form-group">
          <Select
            label="Select Existing Author"
            options={authors.map((author) => ({
              value: author.name,
              label: author.name,
            }))}
            value={author}
            onChange={handleAuthorChange}
          />
          <input
            type="text"
            placeholder="Author Name"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Author Link"
            value={authorLink}
            onChange={(e) => setAuthorLink(e.target.value)}
          />
        </div>
        <div className="form-group">
          <Select
            label="Select Existing Artist"
            options={artists.map((artist) => ({
              value: artist.name,
              label: artist.name,
            }))}
            value={artist}
            onChange={handleArtistChange}
          />
          <input
            type="text"
            placeholder="Artist Name"
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Artist Link"
            value={artistLink}
            onChange={(e) => setArtistLink(e.target.value)}
          />
        </div>
        <div className="modal-controls">
        <Button
          variant="success"
          label="Save Changes"
          onClick={() =>
            handleSaveChanges({
              author,
              authorLink,
              artist,
              artistLink,
            })
          }
        />
        <Button variant="error" label="Delete Image" onClick={handleDeleteImage}/>
        <Button variant="secondary" label="Cancel" onClick={closeModal}/>
        </div>
      </div>
    </div>
  );
};

export default EditImageModal;
