import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { AuthProvider } from "./Utils/Auth/AuthContext";
import ProtectedRoute from "./Utils/Auth/AuthRoute";
import HomePage from "./Pages/Home/Home";
import InfoPage from "./Pages/Info/Info";
import GalleryPage from "./Pages/Gallery/Gallery";
import LoginPage from "./Pages/Login/Login";
import DashboardPage from "./Pages/DashboardPage/DashBoard";

import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Header></Header>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/info" element={<InfoPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<DashboardPage />} />
          </Route>
        </Routes>
        <Footer></Footer>
      </Router>

      <ScrollToTop />
    </AuthProvider>
  );
};

export default App;
