import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import "./EditYoutubeModal.scss";

const EditYouTubeVideoModal = ({
  selectedVideo,
  handleSaveChanges,
  handleDeleteVideo,
  closeModal
}) => {
  const [videoName, setVideoName] = useState(selectedVideo.videoName);
  const [videoUrl, setVideoUrl] = useState(selectedVideo.videoUrl);

  const handleSave = () => {
    const updatedVideo = { ...selectedVideo, videoName, videoUrl };
    handleSaveChanges(updatedVideo);
  };

  return (
    <div className="edit-youtube-video-modal">
      <div className="modal-content">
        <h2>Edit YouTube Video</h2>
        <label>
          Video Name:
          <input
            type="text"
            value={videoName}
            onChange={(e) => setVideoName(e.target.value)}
          />
        </label>
        <label>
          Video URL:
          <input
            type="text"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
          />
        </label>
        <div className="modal-actions">
          <Button label="Save Changes" onClick={handleSave} variant="primary" />
          <Button label="Delete Video" onClick={handleDeleteVideo} variant="error" />
          <Button label="Cancel" onClick={closeModal} variant="secondary" />
        </div>
      </div>
    </div>
  );
};

EditYouTubeVideoModal.propTypes = {
  selectedVideo: PropTypes.object.isRequired,
  handleSaveChanges: PropTypes.func.isRequired,
  handleDeleteVideo: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default EditYouTubeVideoModal;
