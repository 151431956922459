import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

const Button = ({
  variant = 'primary',
  label = '',
  onClick = () => {}, 
}) => {
  const buttonClass = `button ${[variant]}`;

  return (
    <button className={buttonClass} onClick={onClick}>
      {label}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'warning', 'error', 'success']),
  onClick: PropTypes.func,
  label: PropTypes.node.isRequired,
};

export default Button;
